#main-footer {
  background-image: linear-gradient(to bottom, #212c59, #161d3b);
  position: relative;
  width: 100%;
  height: 480px;
  padding-top: 100px;

  @media (max-width: 768px) {
    height: 100%;
  }

  .container {
    position: relative;

    .content {
      display: flex;
      justify-content: space-around;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      p {
        color: white;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 40px;
      }

      span {
        display: block;
        color: white;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 10px;
      }

      .services {
        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
        }
      }

      .address {
        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
        }
      }

      .contact {

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
        }

        .phone {
          font-size: 32px;
          font-weight: 300;
          line-height: 1.3;

          @media (max-width: 768px) {
            margin-top: -10px;
          }

          strong {
            font-size: 20px;
            vertical-align: 8px;
            margin-right: 6px;
          }
        }
      }

      &:before {
        content: '';
        background: #5484f0;
        display: block;
        width: 1020px;
        height: 1px;
        position: absolute;
        bottom: 90px;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .copyright {
      color: #5384ef;
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-top: 150px;
      padding: 0 90px;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 0;
        padding: 0;
      }

      span {
        @media (max-width: 768px) {
          margin-bottom: 40px;
        }
      }

      a {
        display: inline-block;

        @media (max-width: 768px) {
          margin-bottom: 40px;
        }

        svg path {
          fill: #5384ef;
          transition: 250ms;
        }

        &:hover svg path {
          fill: white;
        }
      }
    }

    #back-to-top {
      position: absolute;
      right: -10px;
      top: 0;

      @media (max-width: 768px) {
        display: none;
      }

      img {
        text-align: center;
        display: block;
        position: relative;
        width: 100%;
        margin-bottom: 8px;
        transition: 250ms;

        &:hover {
          opacity: .7;
        }
      }

      span {
        color: white;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 1.4px;
      }
    }
  }
}

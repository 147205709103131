.page-budget {
  
  .section-budget {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 120px 0;

    @media (max-width: 768px) {
      padding: 200px 0;
    }

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
      }
    }

    .content {
      color: white;
      position: relative;
      width: 100%;
      text-align: center;

      &-description {
        margin: 0 auto;
        margin-top: 30px;

        @media (max-width: 768px) {
          margin-top: 80px;
        }

        p {
          font-size: 18px;
          line-height: 1.6;
          font-weight: 400;
        }
      }

      &-title {
        font-size: 60px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;

        @media (max-width: 768px) {
          font-size: 38px;
        }
      }
    }

    .form {
      background-color: transparent;
      margin-top: 60px;

      &__fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 90px;
        width: 770px;
        max-width: 100%;
        margin: 0 auto;

        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
        }

        .field-group {
          border-bottom: 1px solid #999499;
          transition: border-color 250ms;

          &:not(:last-child) {
            margin-bottom: 28px;
          }

          label {
            color: white;
            font-size: 12px;
            text-transform: uppercase;
            display: block;
            margin-bottom: 8px;
          }

          input,
          textarea {
            color: white;
            font-size: 18px;
            font-weight: 500;
            background-color: transparent;
            border: none;
            width: 100%;
            display: block;
            padding-bottom: 8px;

            &::placeholder {
              color: #7886a3;
              transition: opacity 250ms ease;
            }

            &:focus::placeholder {
              opacity: 0;
            }
          }

          textarea {
            height: 109px;
          }

          &.invalid {
            border-color: red;
          }

          &.focus,
          &.not-empty {
            .placeholder {
              opacity: 0;
            }
          }
        }
      }

      &__submit {
        text-align: center;
        margin-top: 40px;
      }
    }
  }
}

// main
@import '../vendor/normalize/normalize.css';
@import '../vendor/fancybox/jquery.fancybox.min.css';
@import '../vendor/owl/owl.carousel.min.css';

// settings
@import 'reset';
@import 'vars';
@import 'fonts';

body {
  font-family: 'Lato';
  font-size: 16px;
  transition: background-color time(smooth);

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

// main container
.container {
  width: 1200px;
  max-width: calc(100% - 30px);
  margin: 0 auto;
}

// elements
@import 'elements/general';
@import 'elements/forms';

// sections
@import 'sections/header';
@import 'sections/footer';

// pages
@import 'pages/home';
@import 'pages/about';
@import 'pages/services';
@import 'pages/contact';
@import 'pages/partners';
@import 'pages/training';
@import 'pages/budget';
@import 'pages/social';
#main-header {
  background-image: linear-gradient(to bottom, #050d2e, transparent);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 50;
  padding: 25px 0;
  transition: padding time(fast) ease, box-shadow time(fast) ease;

  .container {
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @media (max-width: 768px) {
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
    }

    .logo-header {
      position: relative;
      margin: 10px 40px 0 20px;

      a {
        display: block;
      }

      @media (max-width: 768px) {
        width: 90px;
        margin: 0;

        img {
          width: 90px;
        }
      }
    }

    .menu {
      position: relative;
      display: flex;
      align-items: center;
      color: white;
      flex-grow: 1;
      margin: 0 30px 0px 20px;

      ul {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .item {
          text-transform: uppercase;
          font-weight: 600;
          font-size: 12px;
          transition: 250ms;

          &:hover {
            opacity: 0.65;
          }

          a {
            text-decoration: none;
            color: white;
          }
        }
      }

      .close,
      .phone {
        display: none;
      }

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 500;
        margin: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 250ms, visibility 250ms;

        &.open {
          opacity: 1;
          visibility: visible;
        }

        &::before {
          content: '';
          background: linear-gradient(to left, #15436f, #165c93);
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.98;
        }

        .close {
          display: block;
          width: 31px;
          height: 31px;
          position: absolute;
          top: 45px;
          right: 25px;

          &::before,
          &::after {
            content: '';
            background: white;
            display: block;
            width: 35px;
            height: 3px;
            position: absolute;
            top: 50%;
            left: 50%;
          }

          &::before {
            transform: translate(-50%, -50%) rotate(-45deg);
          }

          &::after {
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }

        ul {
          text-align: center;
          display: block;
          margin-bottom: 60px;

          li {
            margin: 0;

            &:not(:last-child) {
              margin-bottom: 28px;
            }

            a {
              font-size: 24px;
            }
          }
        }

        .phone {
          display: block;

          span {
            font-size: 24px;
          }

          strong {
            font-size: 16px;
            position: relative;
            top: -1px;
            display: inline-block;
            margin-right: 6px;
          }
        }
      }
    }

    .whatsapp {
      position: fixed;
      top: 43px;
      right: 30px;
      z-index: 45;

      @media (max-width: 768px) {
        top: unset;
        bottom: 40px;
      }

      &::before {
        content: '';
        background-color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90px;
        height: 90px;
        border-radius: 50%;
        opacity: .15;
        z-index: 1;
        transition: opacity time(fast) ease(fade);
        pointer-events: none;
      }

      a {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1px 1px 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: relative;
        z-index: 2;

        svg {
          transform-origin: 50% 50%;
          transform: scale(0.8);

          path {
            fill: #32b17a !important;
          }
        }

      }

      &:hover {
        &::before {
          opacity: .5;
        }
      }
    }

    .phone {
      display: flex;
      justify-content: flex;
      align-items: center;
      color: white;
      font-weight: 800;
      margin-right: 75px;
      transition: 250ms;

      @media (max-width: 768px) {
        display: none;
      }

      &:hover {
        opacity: 0.65;
      }

      a {
        text-decoration: none;
        color: white;
      }

      span {
        font-size: 14px;
        vertical-align: 6px;

        strong {
          font-size: 10px;
          vertical-align: 4px;
          margin: 0 2px 0 10px;
        }
      }
    }

    .menu-toggler {
      border-top: 3px solid white;
      border-bottom: 3px solid white;
      display: none;
      width: 31px;
      height: 31px;
      position: relative;

      &::before {
        content: '';
        background: white;
        width: 100%;
        height: 3px;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -2px;
      }

      @media (max-width: 768px) {
        display: block;
      }
    }
  }
}

.page-services {
  .section-hero {
    display: flex;
    align-items: center;
    width: 100%;
    height: 450px;
    position: relative;
    z-index: 1;
    padding-top: 98px;

    @media (max-width: 768px) {
      padding-top: 1260px;
    }

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
      }
    }
    
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1060px;
      margin-bottom: 650px;

      @media (max-width: 768px) {
        margin-top: 0;
        margin-bottom: 710px;
      }

      .content {
        color: white;
        position: relative;
        width: 100%;
        text-align: center;

        @media (max-width: 768px) {
          height: 1480px;
        }

        &-title {
          font-size: 60px;
          font-weight: 100;
          letter-spacing: 1px;
          line-height: 1;
          margin-bottom: 40px;

          @media (max-width: 768px) {
            font-size: 38px;
          }

          &-2 {
            font-size: 48px;
            font-weight: 100;
            margin-top: 10px;
          }
        }

        .cards {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 30px;
          margin-bottom: 120px;

          @media (max-width: 768px) {
            display: block;
          }
          
          .card {
            height: 380px;
            width: 370px;
            background-color: white;
            border-radius: 8px;
            box-shadow: 0px 40px 80px 0px rgba(33, 44, 88, 0.3);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 40px;

            @media (max-width: 768px) {
              padding: 30px;
              width: 100%;

              &:not(:last-child) {
                margin-bottom: 40px;
              }
            }

            img {
              margin-bottom: 30px;

            }

            .title {
              color: #212c58;
              text-transform: uppercase;
              font-size: 18px;
              font-weight: 600;
              margin-bottom: 30px;
            }

            .description {
              color: #8a8a8a;
              line-height: 1.4;
              margin-bottom: 16px;
            }
          }
        }
      }  
    }
  }

  .section-services {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 600px;
    position: relative;
    z-index: 0;
    margin-top: 180px;

    @media (min-width: 1600px) {
      height: 1100px;
    }

    @media (min-width: 1367px) {
      margin-top: -50px;
      max-height: 980px;
    }

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: -40px;
      left: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 120%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
      }
    }

    .container {
      max-width: 1366px;
      margin: 0 auto;

      @media (min-width: 1367px) {
        margin-top: 200px;
      }

      .content {
        color: white;
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;

        &-title {
          font-size: 60px;
          font-weight: 100;
          letter-spacing: 1px;
          line-height: 1;
          color: #212c58;
          margin-top: 100px;
          margin-bottom: 40px;
        }

        .features {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 30px 40px;
          text-align: left;
          margin: 90px 0;

          @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
            margin: 90px 30px;
          }

          .card {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: left;
            border: 1px solid rgb(63, 97, 173);
            border-radius: 8px;
            height: 100px;
            transition: 250ms;

            &:hover {
              background-color: white;
              border: 1px solid white;
              box-shadow: 0px 14px 30px -21px rgba(0, 18, 91, 0.61);
            }

            &__icon {
              margin-right: 40px;
            }

            &__text {
              color: #212c58;
              font-size: 18px;
              line-height: 1.4;
            }
          }
        }

        .content-cta {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

    }

  }

  .section-contact {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 120px 0;

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
      }
    }

    .content {
      color: white;
      position: relative;
      width: 100%;
      text-align: center;

      &-description {
        margin: 0 auto;
        margin-top: 30px;

        @media (max-width: 768px) {
          margin-top: 80px;
        }

        p {
          font-size: 18px;
          line-height: 1.6;
          font-weight: 400;
        }
      }

      &-title {
        font-size: 60px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
      }
    }

    .form {
      background-color: transparent;
      margin-top: 60px;

      &__fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 90px;
        width: 770px;
        max-width: 100%;
        margin: 0 auto;

        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
        }

        .field-group {
          border-bottom: 1px solid #999499;
          transition: border-color 250ms;

          &:not(:last-child) {
            margin-bottom: 28px;
          }

          label {
            color: white;
            font-size: 12px;
            text-transform: uppercase;
            display: block;
            margin-bottom: 8px;
          }

          input,
          textarea {
            color: white;
            font-size: 18px;
            font-weight: 500;
            background-color: transparent;
            border: none;
            width: 100%;
            display: block;
            padding-bottom: 8px;

            &::placeholder {
              color: #7886a3;
              transition: opacity 250ms ease;
            }

            &:focus::placeholder {
              opacity: 0;
            }
          }

          textarea {
            height: 109px;
          }

          &.invalid {
            border-color: red;
          }

          &.focus,
          &.not-empty {
            .placeholder {
              opacity: 0;
            }
          }
        }
      }

      &__submit {
        text-align: center;
        margin-top: 40px;
      }
    }
  }
}

.page-home {
  .section-hero {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    position: relative;
    z-index: 1;
    padding-top: 117px;

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
      }
    }

    .content {
      color: white;
      position: relative;
      width: 100%;
      text-align: center;

      &-description {
        margin: 0 auto;
        margin-top: 30px;
        max-width: 780px;

        p {
          font-size: 18px;
          line-height: 1.6;
          font-weight: 400;
        }
      }

      &-title {
        font-size: 60px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;

        @media (max-width: 768px) {
          font-size: 42px;
        }
      }

       &-cta {
        margin-top: 60px;
      }
    }

    .scroll-decor {
      display: flex;
      align-items: center;
      font-size: 10px;
      letter-spacing: 1px;
      font-weight: 800;
      text-transform: uppercase;
      position: absolute;
      bottom: 20px;
      left: 50%;
      color: #616571;
      transform: translateX(-50%);

      .arrow {
        background-color: white;
        display: block;
        height: 50px;
        width: 1px;
        position: relative;
        margin: 0 10px 0 8px;

        &::before,
        &::after {
          content: '';
          background: white;
          display: block;
          width: 1px;
          height: 8px;
          position: absolute;
          left: 0;
          bottom: 0;
          transform-origin: 50% 100%;
        }

        &::before {
          transform: rotate(-45deg);
        }

        &::after {
          transform: rotate(45deg);
        }
      }
    }
  }

  .section-about {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 1;
    margin-top: 60px;

    .content {
      color: white;
      position: relative;
      width: 100%;
      text-align: center;

      &-description {
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 60px;
        max-width: 780px;

        p {
          font-size: 18px;
          line-height: 1.6;
          font-weight: 400;
          color: #304e9c;
        }
      }

      &-title {
        font-size: 44px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        color: #212c58;

        @media (max-width: 768px) {
          font-size: 38px;
        }
      }

      .video {
        position: relative;
        background-color: black;
        width: 750px;
        height: 400px;
        margin: 0 auto;
        padding: 0;

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
          height: 300px;
        }

        &-link {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 5;
        }

        &-image {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;

          img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .5;
          }
        }

        &-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          z-index: 2;
          transform: translate(-50%,-50%) scale(0.8);
          transition: transform 250ms;

          /** Mobile. */
          @media (max-width: 1023px) {
            width: 60px;
            height: 60px;
          }

          &:hover {
            transfrom: scale(0.7);
          }
        }

        &:hover .video-icon {
          transform: translate(-50%,-50%) scale(1);
        }
      }

      .features {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px 100px;
        margin: 90px 0;
        padding: 0 90px;

        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
        }

        .card {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: left;

          @media (max-width: 768px) {
            flex-direction: column;
            text-align: center;
          }

          &__icon {
            margin-right: 40px;

            @media (max-width: 768px) {
              margin-right: 0;
              margin-bottom: 40px;
            }
          }

          &__text {
            color: #808080;
            font-size: 16px;
            line-height: 1.4;
          }
        }
      }

      .content-cta {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .section-services {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 0;
    margin-top: 230px;

    @media (max-width: 768px) {
      margin-top: 90px;
    }

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: -400px;
      left: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 120%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
      }
    }

    .content {
      color: white;
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 1280px;
      margin: 0 auto;
      text-align: center;

      &-title {
        font-size: 60px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        color: #212c58;
        margin-bottom: 40px;

        @media (max-width: 768px) {
          font-size: 38px;
        }
      }

      .features {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px 40px;
        text-align: left;
        margin: 90px 0;
        padding: 0 90px;

        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
          padding: 0 50px;
        }

        .card {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: left;
          border: 1px solid rgb(63, 97, 173);
          border-radius: 8px;
          height: 100px;
          transition: 250ms;

          &:hover {
            background-color: white;
            border: 1px solid white;
            box-shadow: 0px 14px 30px -21px rgba(0, 18, 91, 0.61);
          }

          &__icon {
            margin-right: 40px;
          }

          &__text {
            color: #212c58;
            font-size: 18px;
            line-height: 1.4;
          }
        }
      }

      .content-cta {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .section-partners {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 1;

    .content {
      color: white;
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;

      &-title {
        font-size: 60px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        color: #212c58;
        margin: 100px 0;

        @media (max-width: 768px) {
          font-size: 38px;
          margin: 50px 0;
        }
      }

      .slide {
        position: relative;
        margin-bottom: 90px;

        &-navigation {
          display: flex;
          justify-content: space-between;
          right: -15px;
          left: -15px;
          position: absolute;
          top: 50%;
          z-index: 5;
          transform: translateY(-50%);
          pointer-events: none;

          .arrow {
            pointer-events: all;

            &-button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
            }
          }
        }

        .owl-dots {
          display: flex;
          text-align: center;
          justify-content: center;
          width: 100%;
          margin-top: 20px;

          .owl-dot {
            background-color: rgba(33, 44, 88, 0.3);
            width: 8px;
            height: 8px;
            margin: 0 5px;
            border-radius: 50%;

            &.active {
              background-color: rgb(33, 44, 88);
            }

            &:hover {
              opacity: 0.5;
              transition: 0.3s;
            }
          }
        }

        &-items {
          .item {
            display: grid;
            grid-template-columns: repeat(5, 1fr);

            @media (max-width: 768px) {
              grid-template-columns: repeat(2 ,1fr);
            }
          }

          .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 140px;

            @media (max-width: 768px) {
              height: 90px;
            }

            img {
              display: block;
              width: auto;
              height: auto;
              max-width: 100%;

              @media (max-width: 568px) {
                max-width: 70%;
                max-height: 70%;
              }
            }
          }
        }
      }
    }
  }

  .section-social {
    display: flex;
    align-items: center;
    width: 100%;
    height: 590px;
    position: relative;
    z-index: 1;

    .content {
      color: white;
      position: relative;
      width: 100%;
      height: 100%;
      text-align: left;

      .background {
        width: 100%;
        height: 550px;
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../assets/img/home/impacto-social.png");

        @media (max-width: 768px) {
          background-position-x: -105px;
          background-position-y: 40px;
          height: 580px;
        }
        
        .container {          
          .wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 500px;
            margin: 0 0 0 600px;

            @media (max-width: 768px) {
              width: 100%;
              margin: 10px;
              align-items: center;
            }

            .content-title {
              font-size: 60px;
              font-weight: 100;
              letter-spacing: 1px;
              line-height: 1;
              position: relative;
              top: 100px;

              @media (max-width: 768px) {
                text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7)
              }
            }

            .content-description {
              max-width: 600px;
              font-size: 18px;
              line-height: 1.6;
              font-weight: 400;
              position: relative;
              top: 140px;

              @media (max-width: 768px) {
                text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
              }
            }

            .content-cta {
              position: relative;
              top: 210px;
            }
          }
        }
      }
    }
  }

  .section-contact {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 120px 0;

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
      }
    }

    .content {
      color: white;
      position: relative;
      width: 100%;
      text-align: center;

      &-description {
        margin: 0 auto;
        margin-top: 30px;

        @media (max-width: 768px) {
          margin-top: 80px;
        }

        p {
          font-size: 18px;
          line-height: 1.6;
          font-weight: 400;
        }
      }

      &-title {
        font-size: 60px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
      }
    }

    .form {
      background-color: transparent;
      margin-top: 60px;

      &__fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 90px;
        width: 770px;
        max-width: 100%;
        margin: 0 auto;

        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
        }

        .field-group {
          border-bottom: 1px solid #999499;
          transition: border-color 250ms;

          &:not(:last-child) {
            margin-bottom: 28px;
          }

          label {
            color: white;
            font-size: 12px;
            text-transform: uppercase;
            display: block;
            margin-bottom: 8px;
          }

          input,
          textarea {
            color: white;
            font-size: 18px;
            font-weight: 500;
            background-color: transparent;
            border: none;
            width: 100%;
            display: block;
            padding-bottom: 8px;

            &::placeholder {
              color: #7886a3;
              transition: opacity 250ms ease;
            }

            &:focus::placeholder {
              opacity: 0;
            }
          }

          textarea {
            height: 109px;
          }

          &.invalid {
            border-color: red;
          }

          &.focus,
          &.not-empty {
            .placeholder {
              opacity: 0;
            }
          }
        }
      }

      &__submit {
        text-align: center;
        margin-top: 40px;
      }
    }
  }
}

.page-about {
  .section-hero {
    display: flex;
    align-items: center;
    width: 100%;
    height: 450px;
    position: relative;
    padding-top: 98px;

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
      }
    }
    
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .content {
        color: white;
        text-align: center;
        width: 700px;
        max-width: 100%;

        &-title {
          font-size: 60px;
          font-weight: 100;
          letter-spacing: 1px;
          line-height: 1;

          @media (max-width: 768px) {
            font-size: 48px;
          }

          &-2 {
            font-size: 48px;
            font-weight: 100;
            margin-top: 10px;

            @media (max-width: 768px) {
              font-size: 38px;
            }
          }
        }

         &-cta {
          margin-top: 60px;
        }
      }  
    }
  }

  .section-about {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;

      .content {
        color: white;
        position: relative;
        width: 790px;
        max-width: 100%;
        text-align: center;

        &-description {
          color: #304e9c;
          margin: 0 auto;
          text-align: center;

          p {
            font-size: 18px;
            line-height: 1.6;
            font-weight: 400;
          }
        }

        .scroll-decor {
          display: flex;
          align-items: center;
          font-size: 10px;
          letter-spacing: 1px;
          font-weight: 800;
          text-transform: uppercase;
          color: #616571;
          width: 100px;
          margin: 40px auto 0;

          .arrow {
            background-color: #304e9c;
            display: block;
            height: 50px;
            width: 1px;
            position: relative;
            margin: 0 10px 0 8px;

            &::before,
            &::after {
              content: '';
              background: #304e9c;
              display: block;
              width: 1px;
              height: 8px;
              position: absolute;
              left: 0;
              bottom: 0;
              transform-origin: 50% 100%;
            }

            &::before {
              transform: rotate(-45deg);
            }

            &::after {
              transform: rotate(45deg);
            }
          }
        }

        &-title {
          font-size: 44px;
          font-weight: 100;
          letter-spacing: 1px;
          line-height: 1;
          color: #212c58;
        }

        .video {
          position: relative;
          background-color: black;
          width: 750px;
          height: 400px;
          margin-top: 60px;

          /** Mobile. */
          @media (max-width: 1023px) {
            width: 100%;
            height: 300px;
          }

          &-link {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 5;
          }

          &-image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;

            img {
              position: relative;
              width: 100%;
              height: 100%;
              object-fit: cover;
              opacity: .5;
            }
          }

          &-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            z-index: 2;
            transform: translate(-50%,-50%) scale(0.8);
            transition: transform 250ms;

            /** Mobile. */
            @media (max-width: 1023px) {
              width: 60px;
              height: 60px;
            }

            &:hover {
              transfrom: scale(0.7);
            }
          }

          &:hover .video-icon {
            transform: translate(-50%,-50%) scale(1);
          }
        }
      }
    }
  }

  .section-testimonials {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: -200px;
    padding-top: 280px;

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 70px;
      left: 0;
      z-index: 1;

      img {
        width: 100%;
        height: 120%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
      }
    }
    
    .container {
      position: relative;
      z-index: 2;

      .content {
        color: white;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .about {
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 1px;
          margin-top: 240px;
          color: white;
          position: relative;
          z-index: 3;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 90px;
          text-align: left;
          max-width: 100%;
          margin: 0 auto;

          @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 40px;
            text-align: center;
          }

          p {
            line-height: 1.6;
          }
        }
      }

      .title {
        font-size: 48px;
        text-align: center;
        font-weight: 100;
        letter-spacing: 1px;
        color: white;
        margin-top: 70px;
      }

      .gallery {
        margin: 80px 0;
        position: relative;

        .arrows {
          display: flex;
          justify-content: space-between;
          position: absolute;
          top: 30%;
          left: 0;
          right: 0;
          z-index: 9;
          pointer-events: none;

          .arrow {
            opacity: 1;
            transition: 250ms;
            pointer-events: all;

            @media (max-width: 768px) {
              display: none;
            }

            &:hover {
              opacity: .2;
            }
          }      
        }


        .item {
          max-width: 860px;
          margin: 0 auto;
          color: white;
          padding: 0 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          &__content {
            font-style: italic;
            font-size: 18px;
            line-height: 1.5;
            text-align: center;
          }

          &__author {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 44px;

            &-name {
              font-weight: 600;
              margin-bottom: 10px;
            }
          }
        }

        &__navigation {
          display: flex;
          align-items: center;
          position: relative;
          justify-content: center;
          right: 0;
          bottom: 0;
          z-index: 2;

          @media (max-width: 768px) {
            position: static;
            justify-content: center;
            margin-top: 30px;
          }

          &-dots {
            position: relative;
            bottom: 10px;
            margin: 20px;
          }

          .owl-dot {
            display: inline-block;
            width: 100%;
            background-color: rgba(255,255,255,0.3);
            width: 8px;
            height: 8px;
            margin-left: 5px;
            margin-right: 5px;
            border-radius: 50%;
            margin-top: 20px;

            &.active {
              background-color: rgb(255,255,255);
            }

            &:hover {
              opacity: 0.5;
              transition: 0.3s;
            }
          }
        }
      }
    }
  }

  .section-services {
    position: relative;
    z-index: 1;
    padding-bottom: 100px;

    .content {
      .container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 30px;
        margin-bottom: 120px;

        @media (max-width: 768px) {
          display: block;
        }
        
        .card {
          text-align: center;
          background-color: white;
          border-radius: 8px;
          box-shadow: 0px 40px 80px 0px rgba(33, 44, 88, 0.3);
          padding: 40px;

          @media (max-width: 768px) {
            padding: 40px 30px;

            &:not(:last-child) {
              margin-bottom: 30px;
            }
          }

          img {
            display: inline-block;
            margin-bottom: 40px;

            @media (max-width: 768px) {
              margin-bottom: 28px;
            }
          }

          .title {
            color: #212c58;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 24px;

            @media (max-width: 768px) {
              margin-bottom: 18px;
            }
          }

          .description {
            color: #8a8a8a;
            line-height: 1.4;
          }
        }
      }

      .content-cta {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

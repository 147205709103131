.page-social {
  .section-hero {
    display: flex;
    align-items: center;
    width: 100%;
    height: 450px;
    position: relative;
    z-index: 1;
    padding-top: 98px;

    .background {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      @media (max-width: 768px) {
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
        max-height: 470px;
      }
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        margin-top: 0;
      }

      .title {
        font-size: 60px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        color: white;

        @media (max-width: 768px) {
          font-size: 38px;
          text-align: center;
        }
      } 
    }
  }

  .section-social {
    margin-top: 70px;

    .container {
      .content {
        color: white;
        position: relative;
        width: 790px;
        max-width: 100%;
        height: 100%;
        text-align: center;
        margin: 60px auto 110px;

        &-description {
          color: #304e9c;
          margin: 0 auto;
          text-align: center;
          margin-top: -30px;
          margin: 60px auto 50px;

          p {
            font-size: 18px;
            line-height: 1.6;
            font-weight: 400;
          }
        }

        .scroll-decor {
          display: flex;
          align-items: center;
          font-size: 10px;
          letter-spacing: 1px;
          font-weight: 800;
          text-transform: uppercase;
          position: absolute;
          top: 140px;
          left: 50%;
          color: #616571;
          transform: translateX(-50%);

          @media (max-width: 768px) {
            display: none;
          }

          .arrow {
            background-color: #304e9c;
            display: block;
            height: 50px;
            width: 1px;
            position: relative;
            margin: 0 10px 0 8px;

            &::before,
            &::after {
              content: '';
              background: #304e9c;
              display: block;
              width: 1px;
              height: 8px;
              position: absolute;
              left: 0;
              bottom: 0;
              transform-origin: 50% 100%;
            }

            &::before {
              transform: rotate(-45deg);
            }

            &::after {
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    .flex-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 190px;

      @media (max-width: 768px) {
        margin-top: 0;
        flex-direction: column;
      }

      .box {
        border: 1px solid #304e9c;
        border-radius: 4px;
        width: calc(50% - 30px);
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 40px 80px;

        @media (max-width: 768px) {
          width: 100%;
        
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }

        .title {
          font-size: 18px;
          color: rgb(33, 44, 88);
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 40px;
        }

        ul li {
          display: flex;
          align-items: flex-start;
          font-size: 16px;
          color: #808080;
          line-height: 1.5;

          &:not(:last-child) {
            margin-bottom: 30px;
          }

          &::before {
            content: '';
            border-radius: 50%;
            display: block;
            width: 6px;
            height: 6px;
            background-color: #212c58;
            flex-shrink: 0;
            margin-right: 8px;
            position: relative;
            top: 10px;
          }
        }
      }
    }
  }

  .section-gallery {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 1;

    .container {
      width: 1000px;
      
      .gallery {
        margin: 80px 0;
        position: relative;

        .arrows {
          display: flex;
          justify-content: space-between;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          z-index: 9;
          pointer-events: none;

          .arrow {
            opacity: 1;
            transition: 250ms;
            pointer-events: all;

            @media (max-width: 768px) {
              display: none;
            }

            &:hover {
              opacity: .2;
            }
          }      
        }


        .item {
          max-width: 860px;
          margin: 0 auto;

          &__row-image {
            display: flex;
            height: 550px;
            justify-content: center;

            @media (max-width: 568px) {
              height: 220px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        &__navigation {
          display: flex;
          align-items: center;
          position: relative;
          justify-content: center;
          right: 0;
          bottom: 0;
          z-index: 2;

          @media (max-width: 768px) {
            position: static;
            justify-content: center;
            margin-top: 30px;
          }

          &-dots {
            position: relative;
            bottom: 10px;
            margin: 0 20px;
          }

          .owl-dot {
            display: inline-block;
            width: 100%;
            background-color: rgba(33,44,88,0.3);
            width: 8px;
            height: 8px;
            margin-left: 5px;
            margin-right: 5px;
            border-radius: 50%;
            margin-top: 20px;

            &.active {
              background-color: rgb(33,44,88);
            }

            &:hover {
              opacity: 0.5;
              transition: 0.3s;
            }
          }
        }
      }
    }
  }
}

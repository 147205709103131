// Global Styling
.form {
  .field-group {
    position: relative;
    margin-bottom: 30px;
    transition: opacity 300ms;

    &::before {
      content: '';
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transition: background-color time(smooth);
    }

    .placeholder {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      transition: opacity time(default);
    }

    .field {
      background-color: transparent;
      border: none;
      width: 100%;
      padding-bottom: 15px;
      resize: none;
    }

    // @type [custom-radio]
    &[data-type="custom-radio"] {
      margin-bottom: 35px;

      &::before {
        content: none;
      }

      .radio-option {
        display: inline-block;

        &:not(:last-child) {
          margin-right: 35px;
        }

        label {
          display: flex;
          align-items: center;
          cursor: pointer;

          &::before {
            content: '';
            border: 2px solid;
            border-radius: 50%;
            width: 14px;
            height: 14px;
            position: relative;
            top: -1px;
            margin-right: 10px;
            transition: background-color time(smooth), border-color time(smooth);
          }
        }

        input[type="radio"] {
          display: none;
        }
      }
    }

    // @type [file]
    &[data-type="file"] {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 10px;

      input[type="file"] {
        display: none;
      }

      label {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          color: #656565;
          font-size: 1.4rem;
          transition: color time(smooth);
        }

        svg {
          position: relative;
          top: -2px;
          margin-left: 13px;
        }
      }

      &.selected label span {
        color: black;
      }
    }

    &[required] label::after {
      content: '*';
      margin-left: 4px;
    }

    &.focus,
    &.not-empty {
      .placeholder {
        opacity: 0;
      }
    }
  }

  &.disabled {
    cursor: wait;

    .field-group {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

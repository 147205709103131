.page-contact {
  .section-hero {
    display: flex;
    align-items: center;
    width: 100%;
    height: 450px;
    position: relative;
    z-index: 1;
    padding-top: 98px;

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
      }
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 60px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        color: white;

        @media (max-width: 768px) {
          text-align: center;
          font-size: 38px;
        }
      }
    }
  }

  .section-form {
    margin-top: 150px;
    padding-bottom: 100px;

    .container {
      .content {
        color: white;
        position: relative;
        width: 790px;
        max-width: 100%;
        height: 100%;
        text-align: center;
        margin: 60px auto 110px;

        &-description {
          color: #304e9c;
          margin: 0 auto;
          text-align: center;
          margin-top: -30px;

          p {
            font-size: 18px;
            line-height: 1.6;
            font-weight: 400;
          }
        }
      }

      .form {
        background-color: transparent;
        margin-top: 60px;

        .form-row {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .field-group {
            width: 47%;
          }

          .field-group.address {
            width: 74%;
          }

          .field-group.number {
            width: 20%;
          }
        }

        &__fields {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 90px;
          width: 960px;
          max-width: 100%;
          margin: 0 auto;

          @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
          }

          .field-group {
            width: 100%;
            border-bottom: 1px solid #97a6cd;
            transition: border-color 250ms;

            &[data-type="file"] {
              display: flex;
              justify-content: flex-end;
              padding-bottom: 10px;
              color: #304e9c;
              font-size: 12px;
              font-weight: 600;
              letter-spacing: 1px;
              text-transform: uppercase;

              input[type="file"] {
                display: none;
              }

              label {
                display: flex;
                align-items: center;
                cursor: pointer;

                span {
                  color: #304e9c;
                  font-size: 12px;
                  text-transform: uppercase;
                  transition: color time(smooth);
                }

                svg {
                  position: relative;
                  top: -2px;
                  margin-left: 13px;
                }
              }

              &.selected label span {
                color: black;
              }
            }

            svg {
              position: absolute;
              right: 0;
              z-index: -4;
            }

            label {
              color: #304e9c;
              font-size: 12px;
              font-weight: 600;
              letter-spacing: 1px;
              text-transform: uppercase;
              display: block;
              margin-bottom: 14px;
            }

            .field {
              color: #304e9c;
              font-size: 18px;
              font-weight: 500;
              background-color: transparent;
              border: none;
              width: 100%;
              display: block;
              padding-bottom: 12px;

              &::placeholder {
                color: fade-out(#304e9c, 0.7);
                transition: opacity 250ms ease;
              }

              &:focus::placeholder {
                opacity: 0;
              }
            }

            textarea {
              height: 305px;
            }

            &.type-textarea {
              border: none;

              label {
                margin-bottom: 17px;
              }

              .field {
                border: 1px solid #97a6cd;
                padding: 15px;
                transition: border-color 300ms;
              }

              &.invalid .field {
                border-color: red;
              }
            }

            &.invalid {
              border-color: red;
            }

            &.focus,
            &.not-empty {
              .placeholder {
                opacity: 0;
              }
            }
          }

          .form__row {
            display: flex;
            justify-content: space-between;

            &:nth-child(3),
            &:nth-child(5) {
              .field-group {
                width: calc(50% - 15px);
              }
            }

            &:nth-child(4) .field-group {
              &:nth-child(1) {
                width: calc(75% - 15px);
              }

              &:nth-child(2) {
                width: calc(25% - 15px);
              }
            }
          }
        }

        &__submit {
          text-align: center;
          margin-top: 60px;
        }
      }
    }
  }
}

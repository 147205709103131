.btn {
  position: relative;
  padding: 24px 30px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.3px;

  &-primary {
    background-color: white;
    border: 1px solid white;
    color: #212c58;
    transition: 250ms;

    &:hover {
      background-color: transparent;
      color: white;
    }
  }

  &-secondary {
    background-image: linear-gradient(to left, #15436f, #165c93, #165c93, #15436f);
    background-position: left center;
    background-size: 200% 100%;
    border: 1px solid #165c93;
    color: white;
    transition: background-position 500ms cubic-bezier(.4, 0, .2, 1);
    box-shadow: 0px 13px 26px -5px rgba(0, 18, 91, 0.61);

    &:hover {
      background-position: right center;
    }
  }
}

.form-feedback {
  background: fade-out(black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  opacity: 0;
  visibility: hidden;
  transition: opacity 150ms, visibility 150ms;

  .wrapper {
    background: white;
    border-radius: 4px;
    position: relative;
    padding: 24px 40px;
    transform: scale(0.95);
    transition: transform 150ms;

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      transition: opacity 150ms;

      &:hover {
        opacity: 0.5;
      }

      span {
        display: block;
        transform: scaleY(0.8);
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .wrapper {
      transform: scale(1);
    }
  }
}

// Omnes
// -----------------------------------------

// #(100)
// sass-lint:disable indentation
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/Lato-Light.woff2') format('woff2'),
       url('../fonts/Lato-Light') format('woff'),
       url('../fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Lato-Regular.woff2') format('woff2'),
       url('../fonts/Lato-Regular.woff') format('woff'),
       url('../fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Lato-Bold.woff2') format('woff2'),
       url('../fonts/Lato-Bold.woff') format('woff'),
       url('../fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Lato-Black.woff2') format('woff2'),
       url('../fonts/Lato-Black.woff') format('woff'),
       url('../fonts/Lato-Black.ttf') format('truetype');
}
.page-partners {
  .section-hero {
    display: flex;
    align-items: center;
    width: 100%;
    height: 75vh;
    min-height: 450px;
    position: relative;
    z-index: 1;

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
      }
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 60px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        color: white;
      } 
    }
  }

  .section-partners {
    display: flex;
    width: 100%;
    min-height: 600px;
    position: relative;
    z-index: 0;

    .container {
      max-width: 1366px;
      margin: 0 auto;

      @media (max-width: 768px) {
        max-width: calc(100% - 30px);
      }

      .content {
        color: white;
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        margin-bottom: 20px;

        &-title {
          font-size: 48px;
          font-weight: 100;
          letter-spacing: 1px;
          line-height: 1;
          color: #304e9c;
          margin: 40px 0;

          @media (max-width: 768px) {
            font-size: 38px;
          }
        }

        .slide {
          position: relative;
          margin-bottom: 90px;

          &-navigation {
            display: flex;
            justify-content: space-between;
            right: -15px;
            left: -15px;
            position: absolute;
            top: 50%;
            z-index: 5;
            transform: translateY(-50%);
            pointer-events: none;

            .arrow {
              pointer-events: all;

              &-button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
              }
            }
          }

          .owl-dots {
            display: flex;
            text-align: center;
            justify-content: center;
            width: 100%;
            margin-top: 20px;

            .owl-dot {
              background-color: rgba(33, 44, 88, 0.3);
              width: 8px;
              height: 8px;
              margin: 0 5px;
              border-radius: 50%;

              &.active {
                background-color: rgb(33, 44, 88);
              }

              &:hover {
                opacity: 0.5;
                transition: 0.3s;
              }
            }
          }

          &-items {
            .item {
              display: grid;
              grid-template-columns: repeat(5, 1fr);

              @media (max-width: 768px) {
                grid-template-columns: repeat(2 ,1fr);
              }
            }

            .logo {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 140px;

              @media (max-width: 768px) {
                height: 90px;
              }

              img {
                display: block;
                width: auto;
                height: auto;
                max-width: 100%;

                @media (max-width: 568px) {
                  max-width: 70%;
                  max-height: 70%;
                }
              }
            }
          }
        }

        // .features {
        //   display: grid;
        //   grid-template-columns: repeat(5, 1fr);
        //   justify-content: center;
        //   align-items: center;
        //   flex-wrap: wrap;

        //   @media (max-width: 768px) {
        //     flex-direction: column;
        //   }

        //   .card {
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     padding: 20px;

        //     &__text {
        //       color: #212c58;
        //       font-size: 18px;
        //       line-height: 1.4;
        //     }
        //   }
        // }

        .content-cta {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

    }

  }
}

.page-training {
  .section-hero {
    display: flex;
    align-items: center;
    width: 100%;
    height: 75vh;
    min-height: 450px;
    position: relative;
    z-index: 1;

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
      }
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 60px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        color: white;

        @media (max-width: 768px) {
          font-size: 48px;
        }
      } 
    }
  }

  .section-trainings {
    display: flex;
    width: 100%;
    position: relative;
    z-index: 0;

    .container {
      max-width: 1366px;
      margin: 0 auto;

      .content {
        color: white;
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        margin-bottom: 110px;
        margin-top: 60px;

        .features {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 60px 0;

          @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
          }

          .card {
            @media (max-width: 768px) {
              width: 100%;
            }

            &__image {
              img {
                @media (max-width: 768px) {
                  width: 320px;
                }
              }
            }

            &__title {
              color: #212c58;
              font-size: 18px;
              font-weight: 600;
              text-transform: uppercase;
              line-height: 1.4;
              padding: 0 61px;
              text-align: left;
              margin: 20px 0;

              @media (max-width: 768px) {
                text-align: center;
              }
            }

            &__description {
              font-size: 16px;
              line-height: 1.4;
              color: #808080;
              padding: 0 61px;
              text-align: left;

              @media (max-width: 768px) {
                text-align: center;
              }
            }
          }
        }

        .content-cta {
          margin-top: 110px;
        }
      }
    }
  }
}
